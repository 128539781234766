/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Poseidon AmphibWorks Trident LS-1: A $100,000 Electric Car-Boat Hybrid"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "According to USA TODAY, the Poseidon AmphibWorks Trident LS-1, an electric car-boat hybrid, is expected to be sold for around $100,000. The vehicle is designed to reach speeds of up to 60 mph on both land and water, and it features hydrofoils for a smoother ride."), "\n", React.createElement(_components.h2, null, "Features and Target Market"), "\n", React.createElement(_components.p, null, "The Trident LS-1 will have three permanent seats and is targeted towards individuals who live near water and want a vehicle for both land and water transportation."), "\n", React.createElement(_components.h2, null, "Timeline"), "\n", React.createElement(_components.p, null, "The company plans to have a prototype completed by the end of 2024 and start selling to California customers by December 2025."), "\n", React.createElement(_components.p, null, "Reference:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.usatoday.com/story/tech/news/2024/02/18/car-boat-hydrofil-trident-ls-1/72114564007/"
  }, "USA TODAY")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
